export class MilkBottle {

    DEFAULT_ID = "0";
    id = this.DEFAULT_ID;
    url ="";
    name = "";
    cost = "0";
    wanted = "0";
    city = "";
    county = "";
    state ="";


    constructor(mbName, mbUrl) {
        this.name = mbName || "";
        this.url = mbUrl || "";
        // this.cost = mbCost && mbCost >0 ? mbCost : 0;
        // this.wanted = mbWanted && mbWanted >0?  mbWanted:0;
    }


    toJson(){

        return JSON.stringify(this);
    }


    static convertObject(obj){
        let mb  = new MilkBottle();
        Object.assign(mb,obj);
        return mb;
    }




}// end class