<script >



import {MilkBottle} from "@/classes/MilkBottle";


const config = {
  headers: {
    'content-type': 'multipart/form-data',


  }
};

export default {
  watch:{
    // newVal, oldVal
    isEdit :

      function(){
      // console.log("Is Edit changed")
        this.setEditAdd();
      },

    isSearch:
      function(){
        // console.log("isSearch changed")
        this.setEditAdd()
      }

      ,
    isAdd :
      function() { //
      // console.log("isAdd changed")
      //console.log(this.mb.id)
      //   if(this.mb.id ==null || this.mb.id ==="0"){
      //     console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      //   }
      this.setEditAdd();

    }
  },
  emits:[
    "deletedMB","editMB","insertMB","switchToSearch","hasServerError"
  ],
  methods: {
    shouldShow(){
      // console.log("showAddButton")
      // console.log(this.showAddButton)
      return this.showAddButton
    },

    stopClick(evt){
      if(! this.allowClick) {
        evt.preventDefault();
        return false;
      }
    },

    makeDownloadAvailable(evt){
      // console.log("checking...")
      // console.log(evt)
      if(this.isSearch){
        evt.preventDefault();
        return false;
      }else{
        let fileDownload = this.$refs.fileDownload;
        this.allowClick = true;
        fileDownload.click();
        this.allowClick = false;
        return  true;
      }
    },

    blendIn(){
      if(this.isSearch){
        return "bg-light"
      }else{
        return ""
      }
    },

    deleteMilkBottle(){
      let data = new FormData()
      data.set("id",this.mb.id)
      data.set("url",this.mb.url)



      this.$http.post("https://djytffup3psgtdeh4svyxnpcxy0qkkfq.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
        let wasDeleted = JSON.parse(response.data);
        // console.log("deleted: "+wasDeleted)
        if(wasDeleted){
            //console.log(status)
          this.$emit("deletedMB",this.mb.id)

        }
      }).catch(err =>{
        console.log(err)
        //this.$emit("hasServerError",true)
        // console.log(this.mb.id)
        // this.$emit("deletedMB",this.mb.id)
      })
    },

    hidePicture(){

     // console.log("URL")
     //  console.log(this.mb.url)
     //  console.log(this.mb.url.length)
      if(this.mb.url && this.mb.url.length > 0){
        return true;
      }else{
        return false;
      }

    },
    clearAddData(){
      if(this.isAdd){
        this.mb = new MilkBottle();
      }
    },
    setEditAdd(){
      // console.log("isAdd: " +this.isAdd)
      // console.log("isEdit: " +this.isEdit)
      // console.log(this.isAdd)
       if(this.isEdit){
        this.addEdit = "Edit"
      }else{
        this.addEdit = "Add"
      }
      //
      this.clearAddData();
    },

    async getImage() {


      let image = this.$refs.image.files[0];
      console.log("Image size:")
      console.log(image.size/(1024 * 1024))

      let url = this.$refs.url.value;
      let id =this.$refs.id.value;
      // let name = this.$refs.name.value;
      // let city = this.$refs.city.value;
      // let county = this.$refs.county.value;
      // let state = this.$refs.state.value;
      // let cost = this.$refs.cost.value;
      // let wanted = this.$refs.wanted.value;
      // alert(id)
      // alert(this.$refs.id.value)
      if (image && image.size/(1024 * 1024) < 6.0) {
        // alert("here")
        let data = new FormData();
        // alert(document.getElementById("id")
        //alert(image.webkitRelativePath)
        //data.append("filename",image.name)

        data.append('image', image);
        data.append('id', id);
        //data.append("name",name);
        // data.append("city",city);
        // data.append("county",county);
        // data.append("state",state);
        // data.append("cost",cost);
        // data.append("wanted",wanted);
        data.append("url",url);
       // console.log(data.getBoundary)

        //console.log(data)

        // send fetch along with cookies
        //    https://e7bpqc2t25.execute-api.us-east-1.amazonaws.com/prod/saveImage

        //https://bu4gtm5sjypdcxe6h2b7cot2he0osbvy.lambda-url.us-east-1.on.aws
        await this.$http.post("https://bu4gtm5sjypdcxe6h2b7cot2he0osbvy.lambda-url.us-east-1.on.aws",data,
            config).then(response =>{
              //console.log(data.getHeaders)
          // console.log("status: " +response.data)
          if(response.status === 200){
           // console.log(response.data)
            this.mb = MilkBottle.convertObject(response.data)
             //console.log(this.mb)
            this.$emit("hasServerError","gone")
          }
        }).catch(err => {
          console.log(err)

        })



      } else{

        this.$emit("hasServerError",true);
      }
      this.$refs.image.value = "";

    },
    showImage(){
      // console.log("Url")
      // console.log(this.mb.url.length)

      if(this.isSearch){
        return {"d-none":true};
      }else{
        return {"d-none":false};
      }
    },
    showElement(){
      //alert(this.addEdit)
      if( !this.showElements ){
        return {"d-none":true};
      }
      else {
        return {"d-none":false};
      }
    },

    isReadonly(){
      let isReadOnly = true
      // if(this.search ===true){
      //   isReadOnly = true;
      // }
      if(this.addEdit === "Save" || this.addEdit === "Add"){
        isReadOnly = false;
        if(this.$refs.image) {
          this.$refs.image.disabled = false;
        }
      }else{
        if(this.$refs.image) {
          this.$refs.image.disabled = true;
        }
      }
      return isReadOnly;


    },
    async makeEditable(evt){
      if(this.addEdit!== "Save" && this.addEdit!=="Add"){
        evt.preventDefault();
      }
      let data = new FormData()
      data.set("id",this.mb.id)
      data.set("url",this.mb.url)
      data.set("name",this.mb.name)
      data.set("city",this.mb.city)
      data.set("county",this.mb.county)
      data.set("state",this.mb.state)
      data.set("cost",this.mb.cost)
      data.set("wanted",this.mb.wanted)
      if(this.addEdit === "Save" ){
        await this.$http.post("https://u45lxi2wv6fct7ftj3jya7ieou0gjwpv.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          this.mb = MilkBottle.convertObject(response.data)
          this.$emit("editMB",this.mb)
        }).catch(err => {
          console.log(err)
          // this.$emit("hasServerError");

        })
      }
      if(this.addEdit === "Add"){
        // add a new mb
        await this.$http.post("https://u45lxi2wv6fct7ftj3jya7ieou0gjwpv.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
          this.mb = MilkBottle.convertObject(response.data)
          this.$emit("insertMB",this.mb)
        }).catch(err=>{
          console.log(err)
          // this.$emit("hasServerError");

        })


        //
        // if(this.addEdit !=="Edit"){
        //   this.mb = new MilkBottle()
        // }
      }
      if(this.addEdit === "Edit") {
        this.addEdit = "Save";
        this.search = !this.search;
      }
      //
      // this.$emit("switchToSearch")


    }
  },
  beforeUpdate() {
    // this.clearAddData();
  },

  mounted(){
    Object.assign(this.mb,this.milkBottle)



  },
  data(){
    return {
      // name:"test name",
      addEdit: this.isEdit?"Edit":"Add",
      mb : new MilkBottle(),
      search: this.isSearch,
      allowClick: false
    };
  },
  props:{
    showAddButton:{
      type:Boolean,
      default: false
    },
    canLogin:{
      type:Boolean,
      default: false
    },
    isSearch: {
      type:Boolean,
      default: false
    },
    isAdd:{
      type:Boolean,
      default: true
    },
    milkBottle: {
      type:Object,
      deep: true
    },
    showElements:Boolean,
    isEdit:{
      type:Boolean,
      default: false
    }
  },


};

</script>

<template>
  <div class="container-fluid form-group">
    <form @submit.prevent >
      <div class="row m-1" >

        <div class="col text-center">
<!--          :class="showImage()"-->
          <a ref="fileDownload" :href="mb.url" download="file" @click="stopClick" @dblclick="makeDownloadAvailable">
          <img class="border border-primary" alt="picture" :src="mb.url" style="width: 150px" v-show="hidePicture()">
          </a>
          <input  v-if="shouldShow()" ref="image" :class="showImage()"   class="form-control" type="file"  @input="getImage">
          <input ref="url"  name="url" type="hidden"  v-model="mb.url">
          <input ref="id"  name="id" type="hidden" v-model="mb.id">
        </div>
      </div>


      <div class="row m-1" >
        <div class="col-2  form-label align-self-center">
          Name:
        </div>
        <div class="col text-start flex-grow-1">
          <input ref="name" :class="blendIn()" class="form-control" type="text"   :readonly="isReadonly()" v-model="mb.name">
        </div>
        
      </div>

  <div :class="showElement()">

      <div class="row m-1">
        <div class="col-2 form-label align-self-center">
            City:
        </div>
        <div class="col text-start flex-grow-1">
          <input ref="city" class="form-control" type="text"  v-model="mb.city" :readonly="isReadonly()">
        </div>
      </div>
      <div class="row m-1">
        <div class="col-2 form-label align-self-center">
            County:
        </div>
        <div class="col text-start flex-grow-1">
          <input ref="county" class="form-control" type="text"  v-model="mb.county" :readonly="isReadonly()">
        </div>
      </div>
      <div class="row m-1">
        <div class="col-2 form-label align-self-center">
          State:
        </div>
        <div class="col flex-grow-1 text-start">
          <input ref="state" class="form-control" type="text"  v-model="mb.state" :readonly="isReadonly()">
        </div>
      </div>


      <div v-if="shouldShow()" class="row m-1">
        <div class="col-2 form-label">
          Cost:
        </div>

        <div  class=" col flex-grow-1 text-start">
          <div class="input-group">
          <span class="input-group-text">$</span>
          <input ref="cost" type="text" class="form-control" aria-label="Amount (to the nearest dollar)" v-model="mb.cost" :readonly="isReadonly()">
<!--          <span class="input-group-text">.00</span>-->
          </div>
        </div>
      </div>
      <div class="row m-1">
        <div class="col-2 form-label">
          Sell:
        </div>

        <div class=" col  text-start">
          <div class="input-group">
          <span class="input-group-text">$</span>
          <input ref="wanted" type="text" class="form-control" aria-label="Amount (to the nearest dollar)" v-model="mb.wanted" :readonly="isReadonly()">
<!--          <span class="input-group-text">.00</span>-->
          </div>
        </div>


        <div class="row m-4">
          <div class="col-5 text-center">
            <button v-if="shouldShow()" class="btn btn-primary" @click="makeEditable">{{ addEdit }}</button>


          </div>

          <div class="col-2 text-start">


          </div>

          <div class="col-5 text-center">
            <button v-if="shouldShow()" class="btn btn-danger" @click="deleteMilkBottle">
              Delete
            </button>

          </div>
        </div>


      </div>
  </div>

    </form>
  </div>

</template>

<style scoped>

</style>