import { createApp } from 'vue'
import App from './App.vue'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import {BootstrapIconsPlugin} from 'bootstrap-icons-vue';



import axios from 'axios';
import VueAxios from 'vue-axios';

const app =createApp(App);
app.use(BootstrapIconsPlugin);
app.use(VueAxios, axios);


app.mount('#app');
