<template>
  <div class="container-fluid " >

    <div class="row bg-warning-subtle ">
      <div class="col-12 m-3 p-3">
    <h2 style="font-family: 'Borel', serif" class="text-center text-primary">Mike's Milk Bottles</h2>
      </div>
    </div>
    <div class="row bg-danger-subtle " v-if="error && error.length > 0">
      <div class="col-12 text-bg-danger text-center" >
        {{error}}
      </div>
    </div>
    <div class="row bg-warning-subtle ">
      <div class="col-4 p-2 text-center bg-warning-subtle">
        <button @click="setSearch" :class="highLightSearchButton()" class="badge ">Search</button>
      </div>

      <div class="col-4 p-2 text-center bg-warning-subtle">
        <button @click="canLogin = false" v-if="showLoginButton()" :class="highlightLogInButton()"  class="badge  ">Log In</button>
      </div>

      <div class="col-4  p-2 text-center bg-warning-subtle flex-grow-1">
<!--        @click="clearMilkBottle"-->
        <button @click="clearMilkBottle"  :class="highLightAddButton()" class="badge " v-show="showAddButton">Add</button>
      </div>
    </div>

    <div v-if="canLogin"  class="container border border-danger pt-3" v-show="isSearch">
      <div class="row m-1">
        <div class="col-2   form-label align-self-center">
          Name:
        </div>
        <div class="col text-start flex-grow-1">
          <input @input="searchForMilkBottles" class="form-control" type="text" id="name" name="name" ref="name">
        </div>

      </div>

      <div class="row m-1 ">
        <div class="col-2 text-start  form-label align-self-center">
          City:
        </div>
        <div class="col-10 text-start flex-grow-1">
          <input @input="searchForMilkBottles" class="form-control" type="text" id="city" name="city" ref="city">
        </div>
      </div>
      <div class="row m-1 ">
        <div class="col-2  form-label align-self-center">
          County:
        </div>

        <div class="col text-start flex-grow-1">
          <input @input="searchForMilkBottles" class="form-control" type="text" id="county" name="county" ref="county">
        </div>
      </div>
      <div class="row m-1 ">
        <div class="col-2  form-label align-self-center">
          State:
        </div>
        <div class="col text-start flex-grow-1">
          <input @input="searchForMilkBottles" class="form-control" type="text" id="state" name="state" ref="state">
        </div>
      </div>
    </div>
    <div v-else class="container border border-danger pt-3 form-group">

        <div class="row">
          <div class="col-4 text-end align-self-center">
            Name:
          </div>
          <div class="col-8">
            <input v-on:keyup.enter="submitLogIn()" type="text" class="form-control m-2" ref="username" id="username" name="username">
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-end align-self-center" >
            Password:
          </div>
          <div class="col-8">
            <input v-on:keyup.enter="submitLogIn()" type="password" class="form-control m-2" id="password" name="password" ref="password">
          </div>

        </div>
        <div class="row">
          <div class="col-5">
            &nbsp;
          </div>
          <div class="col-4">
            <input ref="submit" @click="logIn" type="button" class="text-bg-danger text-center align-self-center form-control m-2" value="Submit">
          </div>
        </div>


    </div>

<!--    @has-server-error="setError"-->
  <milk-bottle-component @has-server-error="setError" :show-add-button="showAddButton"  @switch-to-search="setSearch" @edit-m-b="editMB" :is-edit="isEdit" @insert-m-b="insertMB" @deleted-m-b="deleteMB" :is-add="isAdd" :show-elements="true" :is-search="isSearch" :can-login="canLogin"  :key="shownMilkBottle.id" :milk-bottle="shownMilkBottle" v-show="!isSearch"></milk-bottle-component>


    <div v-if="isSearch" class="row" v-show="canLogin">
      <div v-for="mb in searchMilkBottles" :key="mb.id" class="col col-xs-12" @click="showSingle(mb)">
<!--        @has-server-error="setError"-->
        <milk-bottle-component @has-server-error="setError" :show-add-button="showAddButton" :can-login="canLogin"  :key="mb.id + mb.url" :class="showImage(mb)" @click="showMilkBottle" :is-edit="isEdit" :is-add="isAdd" :show-elements="false" :is-search="isSearch" :milk-bottle="mb"></milk-bottle-component>
      </div>
    </div>



  </div>



</template>

<script>
  import MilkBottleComponent from "@/components/MilkBottle.vue";
  import {MilkBottle} from "@/classes/MilkBottle";


  //dummy data
  let dummyData = [];
  for (let idx = 1;idx <=10;idx++){
    let mb = new MilkBottle()
    mb.name = "bar" + idx + "foo";

    mb.url = "/images/bottle-of-milk-science-photo-library.jpg"
    mb.id = "d" + idx;
    if(mb.id === 1){
      mb.city = "Sellersville";
    }else if(mb.id ===10){
      mb.city = "Trumbauersville";
    }

    dummyData.push( mb);
  }




  export default {
    components: {MilkBottleComponent},
    data(){
      return {
        isSearch: true,
        canLogin: false,
        milkBottles: [],
        searchMilkBottles: [],
        shownMilkBottle: new MilkBottle(),
        isAdd: false,
        isEdit: true,
        showAddButton: false,
        error: ""
      };

    },
    methods:{
      submitLogIn(){
        let button = this.$refs.submit
        button.click()
      },
      setError(tooBig){
        console.log("Has error")
        console.log("tooBig:")
        console.log(tooBig)
        // console.log(tooBig.size())
        if(tooBig==true){
          this.error = "The image is too large"
        } else if (tooBig === "gone") {
          console.log("should clear")
          this.error = null
        }else {
          this.error = 'That is the wrong username password combination'
        }
        this.$forceUpdate();
      },
      showLoginButton(){
        //return this.isSearch === true && this.canLogin === false
        return this.showAddButton == false
      },
      async logIn(){
        let data = new FormData()

        data.set("username",this.$refs.username.value.trim().toLowerCase())
        //console.log(this.$refs.password.value)
        data.set("password",this.$refs.password.value.trim().toLowerCase())
        await this.$http.post("https://d73fgoo4fvydavi6ik5qqnzjxy0hobyw.lambda-url.us-east-1.on.aws/",data,null).then(response =>{
             // console.log(response.data)
              let isLoggedIn = response.data
              if (isLoggedIn){
                this.canLogin = true
                this.isSearch = true
                this.showAddButton = true
                this.error = null
              }else{
                this.canLogin = false
                this.setError(false)
              }
            }

        ).catch( err => {
          console.log(err)
          this.canLogin = true
          this.setError(false)
        })



      },
      async getAllMBs(){
        // // eslint-disable-next-line no-debugger
        // debugger
          // CHANGED
          await this.$http.get("https://xsp15b9d2k.execute-api.us-east-1.amazonaws.com/prod/getAllMBs").then(response =>{
       // await this.$http.get("/getAllMilkBottles").then(response => {
          let mbs = response.data.body

            //console.log(mbs)
            let mb = []
          if(mbs){
            this.milkBottles =[];
            this.searchMilkBottles = [];
            //console.log(mbs.length)
            for(let idx = 0;idx < mbs.length;idx++){
              mb[idx] = MilkBottle.convertObject(mbs[idx])
               //console.log(mbs[idx])
              this.milkBottles.push(mb[idx])
              this.searchMilkBottles.push(mb[idx])
            }
          }else{
            console.log("help")
          }
          this.error = ""
        }).catch(err=> {
              console.log(err)
              //this.setError();
            }
        )
      },
      showImage(mb){
        if(mb.url ==="" && mb.name.trim() ===""){
          return {"d-none":true};
        }
        else{
          return {"d-none":false}
        }
      },
      showMilkBottle(){
        //this.showAddButton = true;
        this.isAdd=false
        this.isEdit = true;
        this.canLogin = true;
        // this.showAddButton = false;
        // this.isSearch = false;
      },
      clearSearch() {
        //this.canLogin = false
        if (this.$refs.name){
          this.$refs.name.value = " ";
          this.$refs.city.value = " ";
          this.$refs.county.value = " ";
          this.$refs.state.value = " ";

          this.$refs.name.value = "";
          this.$refs.city.value = "";
          this.$refs.county.value = "";
          this.$refs.state.value = "";

          this.shownMilkBottle = new MilkBottle()


          this.$refs.name.dispatchEvent(new Event('input', {bubbles: true}))
          this.$forceUpdate();
          }
      },

      async setSearch(){
        // console.log("NEW STUFF")
        this.canLogin = true;
        this.isSearch=true;
        this.isAdd=false;
        //this.showAddButton=true;
        this.isEdit = true;
        this.clearSearch();
        await this.getAllMBs();
        this.$forceUpdate()

      },

      editMB(milkBottle){
        // eslint-disable-next-line no-debugger
        // debugger
        // console.log("edit MB")
        let mb = new MilkBottle();
        Object.assign(mb,milkBottle);
        let foundIt = false;
        let foundItAlso = false;
        // console.log("MB len: "+this.milkBottles.length)
        for(let idx = 0;idx<this.milkBottles.length;idx++){
          if(this.milkBottles[idx].id === mb.id){
            foundIt = true;
            this.milkBottles.splice(idx,1);
            // break;
          }
        }
        // console.log("SearchMB len: "+this.searchMilkBottles.length)
        for(let idx =0;idx < this.searchMilkBottles.length;idx++){
          if(this.searchMilkBottles[idx].id === mb.id){
            foundItAlso = true;
             this.searchMilkBottles.splice(idx,1);
            // break;
          }
        }
        if(foundIt && foundItAlso){
          // console.log(mb)
          this.milkBottles.push(mb)
          this.searchMilkBottles.push(mb)


        }else{
          console.log("didn't find it")
        }
        this.isSearch =true;
        this.isAdd = false;
        this.showAddButton = true;
        // this.shownMilkBottle = new MilkBottle();
        // this.$refs.name.value = "";
        // this.$refs.state.value = "";
        // this.$refs.city.value = "";
        // this.$refs.county.value = "";

        // this.$forceUpdate()
        this.clearSearch();
      },
      insertMB(milkBottle){
        // eslint-disable-next-line no-debugger
        // debugger
        // console.log("insert db")
        let mb = new MilkBottle();
        Object.assign(mb,milkBottle)
        let idx = this.searchMilkBottles.findIndex(elem => elem.id === mb.id)
        // console.log("search idx: "+ idx)
        if(idx === -1){
          this.searchMilkBottles.push(mb);
        }
        idx = this.milkBottles.findIndex(elem => elem.id === mb.id)
        // console.log("mb idx: "+ idx)
        if(idx === -1){
          this.milkBottles.push(mb);
        }

        this.isSearch =true;
        this.isAdd = false;
        this.showAddButton = true;
        this.editMB(milkBottle)
      },
      deleteMB(id){
        // console.log("id:")
        // console.log(id)

        for(let idx = 0;idx<this.searchMilkBottles.length;idx++){
          if(this.searchMilkBottles[idx].id === id) {
            this.searchMilkBottles.splice(idx,1);
            break;
          }
        }
        for(let idx =0;idx <this.milkBottles.length;idx++){
          if(this.milkBottles[idx].id ===id){
            this.milkBottles.splice(idx,1);
            break;
          }
        }
        this.isSearch =true;
        this.isAdd = false;
        this.showAddButton = true;
        this.clearSearch();
      },
      searchForMilkBottles(){
        // console.log("searching")
        let name = this.$refs.name.value.trim().toLowerCase();
        let city = this.$refs.city.value.trim().toLowerCase();
        let county = this.$refs.county.value.trim().toLowerCase();
        let state = this.$refs.state.value.trim().toLowerCase();
        let filter ={
          name: name,
          city: city,
          state:state,
          county: county
        }
        //console.log(filter)
        // let that = this;

        this.searchMilkBottles =this.milkBottles.filter(elem =>{

          let isTrue = true;
          for(let key in filter){

            if(filter[key]===""){
              continue;
            }


            isTrue = elem[key].trim().toLowerCase().startsWith(filter[key]);
            if(isTrue === false){
              break;
            }
          }
          return isTrue;



        })

      },

      clearMilkBottle(){
        this.canLogin =true
        // this.isAdd = false;
        this.isAdd = true;
        // this.isSearch = true;
        this.isSearch = false;
        // this.isEdit = true;
        this.isEdit = false;

        this.shownMilkBottle = new MilkBottle();
        //this.$emit("")
        this.$forceUpdate();
      //
      },

      highLightAddButton(){
        if(this.isAdd){
          // this.clearMilkBottle();
          return "text-bg-success"

        }else{
          return "text-success"
        }
      }
      ,

      highlightLogInButton(){
        if(!this.canLogin){
          return "text-bg-danger"
        }else{
          return "text-danger"
        }
      },

      highLightSearchButton(){
        if(this.isSearch && this.canLogin){
          return "text-bg-primary"
        }else{
          return "text-primary"
        }
      },
      showSingle(mb){
        this.shownMilkBottle = mb
        //alert (this.shownMilkBottle.name)
        this.isSearch = !this.isSearch
        this.isAdd = false;
      }
    },

    // async created() {
    //   // get the users
    //   await this.$http.get("/getAllUsers").then(response =>{
    //     let users = response.data;
    //     if(users) {
    //       for (let idx = 0; idx < users.length; idx++) {
    //         users[idx] = User.convertObject(users[idx])
    //
    //       }
    //       this.$store.commit("setUsers", users);
    //     }
    //   }).catch(err =>{
    //     console.log(err);
    //   });
    //   // now get the jobs
    //
    //   await this.$http.get("/getAllJobs").then(response =>{
    //     let jobs = response.data;
    //     if(jobs){
    //       for (let idx = 0; idx < jobs.length; idx++) {
    //         jobs[idx]  = Job.convertObject(jobs[idx]);
    //
    //       }
    //       //console.log("Setting users...")
    //       this.$store.commit("setJobs",jobs);
    //     }
    //   }).catch(err =>{
    //     console.log(err);
    //   });
    //
    //   // get the rota
    //
    //   await this.$http.get("/getMonthlyRota?id=" +new moment().toJSON()).then(response =>{
    //     let rota = response.data;
    //     if(rota){
    //       for (let idx = 0; idx < rota.length; idx++) {
    //         rota[idx]  = Appointment.convertObject(rota[idx]);
    //
    //       }
    //
    //       this.$store.commit("setRota",rota);
    //     }
    //   }).catch(err =>{
    //     console.log(err);
    //   });
    //
    //
    //   if(this.users.length===0){
    //     let user = new User("admin");
    //     user.setPassword("admin");
    //     user.id = "1";
    //     user.isAdmin = true;
    //     // let fakeUser = new User("foo","","a really long last name here it is and more");
    //     // fakeUser.setPassword("foo");
    //
    //     this.users.push(user);
    //     //this.users.push(fakeUser);
    //   }
    // },



   async created(){
      // this.milkBottles = dummyData;
      // this.searchMilkBottles = dummyData;
     await this.setSearch()
     //await this.getAllMBs();

    }
  };


</script>

<style>



</style>
